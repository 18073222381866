<template>
  <div class="digitizing-analyze box" :style="backgroundImg">
  <div class="digitizing-swiper box">
    <p>行业分析</p>
    <div class="swiper">
      <a-carousel autoplay>
        <div class="digitizing-analyze-list" v-for="item in val" :key="item[0].id">
          <div>
            <v-img
                class="img"
                :attachment-id="item[0].imgAttmId"
                :default-img="require('@/assets/img/solution/kctp.png')"
            />
            <p>{{ item[0].imgTitle }}</p>
          </div>
          <div  v-if="item[1]">
            <v-img
                class="img"
                :attachment-id="item[1].imgAttmId"
                :default-img="require('@/assets/img/solution/kctp.png')"
            />
            <p>{{ item[1].imgTitle }}</p>
          </div>
        </div>
      </a-carousel>
    </div>
  </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
export default {
  name: "digitizing_analyze",
  components:{
    VImg,
  },
  props: [
      'val',
      'backgroundValue',
  ],
  data(){
    return{
      backgroundImg:{
        backgroundImage: 'url('+require("@/assets/img/solution/digitizing5.png")+')'
      }
    }
  },
}
</script>

<style scoped lang="scss">
.box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > p {
    color: #333333;
    font-size: 30px;
  }
}

.digitizing-analyze{
  background-repeat: no-repeat;
  background-size: cover ;
}
.digitizing-text{
  width: 1280px;
  //height: 386px;
  padding-top: 50px;
  padding-bottom: 100px;
  >div{
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    margin-top: 50px;
  }
}

.digitizing-swiper {
  height: 662px;
  //background: #FFFFFF;
  .swiper {
    margin-top: 50px;
    width: 1280px;
    height: 546px;
    .digitizing-analyze-list {
      display: flex !important;
      justify-content: space-between;

      > div {
        > .img {
          display: block;
          width: 628px;
          height: 470px;
        }

        > p {
          margin: 24px 0;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

      .ant-carousel::v-deep{

        .slick-dots{
          li {
            button {
              background: #ccc;
            }
          }
          .slick-active{
            button {
              background: #EA0B06;
            }
          }
        }
      }





  }
}
</style>
