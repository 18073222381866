import { PortalRequest as request } from "@/utils/request";

// 分页查看解决方案
export const getSolutionList = (params, data) => {
    return request({
        url: "/solution/page-list",
        method: "post",
        params: params,
        data,
    });
};

// 解决方案类别
export const getCategoryList = (type) => {
    return request({
        url: `/category/${type}/list`,
        method: "get",
    });
};

// 获取解决方案信息
export const getSolutionInfo = (id) => {
    return request({
        url: `/solution/${id}/detail`,
        method: "get",
    });
};

// 获取解决方案架构信息
export const getProductInfo = (data) => {
    return request({
        url: `/product/selectProductExtend`,
        method: "post",
        data
    });
};

// 首页解决方案列表
export const getHomeSolution = (params) => {
    return request({
        url: `/solution/homePage`,
        method: "post",
        params
    });
};