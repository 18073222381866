<template>
  <div class="things-head">
    <div class="img-box active">
      <v-img
        v-if="val.backgroundImg"
        :attachment-id="val.backgroundImg"
        :default-img="require('@/assets/img/solution/photo.png')"
      />
    </div>
    <div class="content">
      <span>{{ val.name }}</span>
      <p>{{ val.text }}</p>
      <a-button type="danger" class="btn-red" @click="consult">
        国产替代咨询
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VImg from "@/components/VImg";
export default {
  name: "things_head",
  computed: {
    ...mapGetters(["isLogin", "settings"]),
    /*srcImg() {
      let src=`${this.settings.OBS_PREFIX}/${this.val.backgroundImg}`
      return {
        backgroundImage: 'url(' + src + ')'
      }
    },*/
  },
  components: {
    VImg,
  },
  props: ["val"],
  data() {
    return {};
  },

  methods: {
    // 国产替代咨询
    consult() {
      window.open(`/account/userLayout/userScenes/add`, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.active {
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: linear-gradient(
      90deg,
      #eef3f9 0%,
      #eaf1f7 54%,
      rgba(235, 241, 248, 0) 79%,
      rgba(236, 241, 249, 0) 100%
    );
  }
  > .img-box {
    > img {
      left: auto;
      width: 1037px;
    }
  }
}

.things-head {
  background: #fff;
  height: 360px;
  position: relative;
  display: flex;
  justify-content: center;
  > .img-box {
    width: 1920px;
    height: 360px;
    position: relative;
    > img {
      max-width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }

  > .content {
    position: absolute;
    z-index: 3;
    width: 1280px;
    top: 99px;
    left: 50%;
    margin-left: -640px;
    > span {
      line-height: 64px;
      font-size: 48px;
      display: block;
      margin-bottom: 13px;
    }
    > p {
      display: flex;
      align-items: center;
      line-height: 20px;
      margin-bottom: 24px;
    }
    > button {
      font-size: 14px;
      width: 160px;
    }
  }

  .ant-btn::v-deep {
    width: 132px;
    height: 40px;
    font-size: 14px;
  }
}
</style>
