<template>
  <div class="consult" :style="backgroundCon">
    <a-form-model
        ref="ruleForm"
        layout="inline"
        class="form"
        :rules="rules"
        :model="formInline"
    >
      <a-form-model-item
          ref="company"
          prop="company"
      >
        <a-input
            v-model="formInline.company"
            type="text"
            placeholder="请输入公司名称"
            :maxLength=20
            @blur="
                    () => {
                        $refs.company.onFieldBlur();
                     }
                "
        >

        </a-input>
      </a-form-model-item>
      <a-form-model-item
          ref="user"
          prop="user"
      >
        <a-input
            v-model="formInline.user"
            type="text"
            placeholder="请输入称呼"
            :maxLength=10
            @blur="
                    () => {
                      $refs.user.onFieldBlur();
                    }
                  "
        >
        </a-input>
      </a-form-model-item>

      <a-form-model-item
          ref="phone"
          prop="phone"
      >
        <a-input
            v-model="formInline.phone"
            type="tel"
            placeholder="请输入手机号"
            :maxLength=11
            @blur="
                    () => {
                      $refs.phone.onFieldBlur();
                    }
                  "
        >
        </a-input>
      </a-form-model-item>

      <a-form-model-item>
        <a-button class="btn-red"  type="danger" @click="onSubmit">
          我要咨询
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {getConsultCreate} from "@/api/news";

export default {
  name: "sub_bottom",
  /*props:{
    val:{
      type: Object,
      default() {
        return {
          company:"",
          user: "",
          phone: "",
        }
      }
    }
  },*/
  data() {
    const validatorPhone = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确!"));
      } else {
        callback();
      }
    };
    return {
      backgroundCon:{
        backgroundImage: 'url('+require("@/assets/img/solution/things6.png")+')',
      },

      formInline: {
        company:"",
        user: "",
        phone: "",
      },

      rules: {
        company: [
          { required: true, message: "请输入公司名称", trigger: "change" },
        ],
        user: [
          { required: true, message: "请输入称呼", trigger: "change" },
        ],
        phone: [
          { required: true, validator: validatorPhone, trigger: "change" },
        ],
      },


    }
  },

  methods:{
    onSubmit() {
      let { formInline } = this;
      let _this = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            contactCompany: formInline.company,
            contactPerson: formInline.user,
            contactDetail: formInline.phone,
          };
          getConsultCreate(data).then((res) => {
            _this.$message.success("提交成功！");
            _this.$refs.ruleForm.resetFields();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },


}
</script>

<style scoped lang="scss">
.consult{
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover ;
  height: 227px ;
  display: flex;
  justify-content: center;
  padding-top: 82px;
  >.form{
    width: 1280px;
    display: flex;
    justify-content: space-between;
    .ant-input::v-deep{
      width: 344px;
      height: 64px;
      font-size: 18px;
    }
    .ant-btn::v-deep{
      width: 200px;
      height: 64px;
      font-size: 18px;
    }

  }
}
</style>
